import * as React from "react";

import ConfirmationLayout from "../../components/shared/confirmation-layout";
import * as Analytics from "../../utils/analytics";

export default function Confirmation() {
  React.useEffect(() => {
    Analytics.track("Lead Capture", {
      location: "Design Services",
    });
  }, []);

  return <ConfirmationLayout homeUrl="/design-services" />;
}
